import React from 'react';
import { Actions, DeviceType } from '@edgeiq/edgeiq-api-js';
import { Grid, Typography, Box, Paper, Tooltip } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// import clsx from 'clsx';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setNewDeviceType } from '../../../redux/reducers/deviceTypes.reducer';
import { DeviceProfileAbility } from '../../../models/common';
import SwitchButton from '../../../components/SwitchButton';
import { deviceTypeAbilitiesGroups } from '../constants';
import useStyles from './styles';

const DeviceTypeAbilites: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const editableDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.newDeviceType,
  );
  const capabilitiesKeys = editableDeviceType?.capabilities.actions;

  const handleAbilitiesSwitchChange = (
    prop: string,
    checked: boolean,
  ): void => {
    dispatch(
      setNewDeviceType({
        ...editableDeviceType,
        capabilities: {
          ...editableDeviceType?.capabilities,
          actions: {
            ...editableDeviceType?.capabilities.actions,
            [prop]: checked,
          },
        },
      } as DeviceType),
    );
  };

  const handleGroupSwitchChange =
    (abilities: DeviceProfileAbility[]) =>
    (_prop: string, checked: boolean): void => {
      const actions: { [key: string]: boolean } = {};
      for (let i = 0; i < abilities.length; i++) {
        const ability = abilities[i];
        actions[ability.key as keyof Actions] = checked;
      }
      dispatch(
        setNewDeviceType({
          ...editableDeviceType,
          capabilities: {
            ...editableDeviceType?.capabilities,
            actions: {
              ...editableDeviceType?.capabilities.actions,
              ...actions,
            },
          },
        } as DeviceType),
      );
    };

  const getGroupSwitchValue = (abilities: DeviceProfileAbility[]): boolean => {
    let returnValue = true;
    for (let i = 0; i < abilities.length; i++) {
      const ability = abilities[i];
      if (capabilitiesKeys && !capabilitiesKeys[ability.key as keyof Actions]) {
        returnValue = false;
        break;
      }
    }
    return returnValue;
  };

  return (
    <Grid container>
      {editableDeviceType && (
        <Paper className="p-7 br-1 shadow">
          <Typography variant="h5" className="mb-6">
            Abilities
          </Typography>
          <Grid container spacing={3}>
            {deviceTypeAbilitiesGroups.map((group) => (
              <Grid item xs={12} md={6} lg={4} key={group.key}>
                <Typography variant="h6" className="mb-2">
                  <span className="mr-2">{group.label}</span>
                  {group.abilities.length !== 1 && (
                    <SwitchButton
                      label=""
                      topMargin={false}
                      value={getGroupSwitchValue(group.abilities)}
                      prop={group.key}
                      onSwitchChange={handleGroupSwitchChange(group.abilities)}
                    />
                  )}
                </Typography>
                {group.abilities.map((ability) => (
                  <Box className="mb-2" key={ability.key}>
                    <SwitchButton
                      topMargin={false}
                      label={ability.label}
                      value={
                        capabilitiesKeys
                          ? capabilitiesKeys[ability.key as keyof Actions]
                          : false
                      }
                      prop={ability.key}
                      onSwitchChange={handleAbilitiesSwitchChange}
                    />
                    <Tooltip placement="top-start" title={ability.description}>
                      <HelpOutlineIcon
                        fontSize="small"
                        className={classes.infoIcon}
                      />
                    </Tooltip>
                  </Box>
                ))}
              </Grid>
            ))}
          </Grid>
        </Paper>
      )}
    </Grid>
  );
};

export default DeviceTypeAbilites;
