import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CommandInput, Commands } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import {
  cleanNewDeviceConfig,
  setNewCommandInput,
} from '../../redux/reducers/commands.reducer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../app/constants';
import { LocationState } from '../../models/common';
import CommandForm from '../../containers/Forms/CommandForm';
import Header from '../../containers/HeaderWithActionButton';
import {
  handleCommandDynamicValues,
  formatedObjectOptions,
} from '../../containers/Forms/CommandForm/helper';
import Publish from '../../containers/Publish';
import useStyles from './styles';

const CreateCommandPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const goBackLabel = (location.state as LocationState)?.goBackLabel;
  const goBackUrl = (location.state as LocationState)?.goBackUrl;
  const commandInput = useAppSelector(
    (state: RootState) => state.commands.newCommandInput,
  );

  const [enableSubmit, setEnableSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [invalidSenderJson, setInvalidSenderJson] = useState(false);
  const [invalidOptionJson, setInvalidOptionJson] = useState(false);
  const [optionsKeys, setOptionsKeys] = useState<string[]>([]);
  const [optionsRows, setOptionsRows] = useState<{ [key: string]: string }[]>(
    [],
  );
  const [shellCommand, setShellCommand] = useState('');

  const checkSubmitEnable = (): void => {
    let missingValues = false;
    if (
      commandInput?.sender_type === 'workflow_sender' &&
      typeof commandInput.sender?.workflow_id !== 'string'
    ) {
      missingValues = true;
    }
    setEnableSubmit(
      commandInput?.company_id !== '' &&
        commandInput?.name !== '' &&
        !invalidSenderJson &&
        !invalidOptionJson &&
        !missingValues,
    );
  };

  useEffect(() => {
    if (commandInput && commandInput.sender && commandInput.sender.command) {
      setShellCommand(commandInput.sender.command as string);
    }
  }, []);

  useEffect(() => {
    checkSubmitEnable();
  }, [commandInput, invalidSenderJson, invalidOptionJson]);

  const handleValueChange = (
    prop: string,
    value: string | number | string[],
  ): void => {
    switch (prop) {
      case 'sender':
      case 'options':
        try {
          const formattedJson = JSON.parse(value as string);
          if (formattedJson.command) {
            setShellCommand(formattedJson.command);
          }
          dispatch(
            setNewCommandInput({
              ...(commandInput as CommandInput),
              [prop]: formattedJson,
            }),
          );
          // JSON.parse doesn't count an array as an error, so this is one more control to make sure it is shown as invalid
          if (Array.isArray(formattedJson)) {
            setInvalidJsonValue(prop, true);
          } else {
            setInvalidJsonValue(prop, false);
          }
        } catch (e) {
          if (!value) {
            setInvalidJsonValue(prop, false);
            return;
          }
          setInvalidJsonValue(prop, true);
        }
        break;
      case 'save_command_output':
        dispatch(
          setNewCommandInput({
            ...(commandInput as CommandInput),
            save_command_output: !commandInput?.save_command_output,
          }),
        );
        break;
      case 'generate_child_command_executions':
        dispatch(
          setNewCommandInput({
            ...(commandInput as CommandInput),
            generate_child_command_executions:
              !commandInput?.generate_child_command_executions,
          }),
        );
        break;
      case 'shellCommand':
        setShellCommand(value as string);
        let sender = (commandInput as CommandInput).sender;
        if (!sender) {
          sender = {};
        }
        sender.command = value;
        dispatch(
          setNewCommandInput({
            ...(commandInput as CommandInput),
            sender,
          }),
        );
        break;
      case 'workflow_id':
        dispatch(
          setNewCommandInput({
            ...(commandInput as CommandInput),
            sender: {
              ...(commandInput as CommandInput).sender,
              workflow_id: value,
            },
          }),
        );
        break;
      case 'unique_identifier':
        dispatch(
          setNewCommandInput({
            ...(commandInput as CommandInput),
            sender: {
              ...(commandInput as CommandInput).sender,
              workflow_id: value,
            },
          }),
        );
        break;
      default:
        dispatch(
          setNewCommandInput({
            ...(commandInput as CommandInput),
            [prop]: value,
          }),
        );
        break;
    }
  };

  const setInvalidJsonValue = (prop: string, value: boolean): void => {
    switch (prop) {
      case 'sender':
        setInvalidSenderJson(value);
        break;
      case 'options':
        setInvalidOptionJson(value);
        break;
    }
  };

  const handlePublishSubmit = (): void => {
    setSubmitting(true);
    Commands.create(commandInput as CommandInput)
      .then((_response) => {
        dispatch(
          setAlert({
            highlight: 'Command created successfully',
            type: 'success',
          }),
        );
        navigate('/commands');
        dispatch(cleanNewDeviceConfig());
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleDynamicChange = (
    prop: string,
    value: string | number,
    field: string,
    index: string,
  ): void => {
    dispatch(
      setNewCommandInput(
        handleCommandDynamicValues(
          commandInput as CommandInput,
          prop,
          value,
          field,
          index,
          optionsKeys || [],
          optionsRows || [],
        ),
      ),
    );
  };

  const handleAddRow = (prop: string): void => {
    switch (prop) {
      case 'attributes':
        const attbArray = commandInput?.sender?.attributes || {};
        dispatch(
          setNewCommandInput({
            ...(commandInput as CommandInput),
            sender: {
              ...commandInput?.sender,
              [`attributes`]: {
                ...(attbArray as { [key: string]: string }),
                '': '',
              },
            },
          }),
        );
        break;
      case 'options':
        const emptyElement = optionsKeys.findIndex((item) => item === '');
        if (emptyElement < 0) {
          setOptionsKeys([...optionsKeys, '']);
          setOptionsRows([...optionsRows, ...[{ default: '', type: '' }]]);
          dispatch(
            setNewCommandInput({
              ...(commandInput as CommandInput),
              options: {
                ...commandInput?.options,
                [``]: {
                  '': '',
                },
              },
            }),
          );
        }
        break;
    }
  };

  const handleRemoveRow = (prop: string, item: string): void => {
    switch (prop) {
      case 'attributes':
        const attbArray: { [key: string]: string } =
          (commandInput?.sender?.attributes as { [key: string]: string }) || {};
        delete attbArray[item];
        dispatch(
          setNewCommandInput({
            ...(commandInput as CommandInput),
            sender: {
              ...commandInput?.sender,
              [`attributes`]: {
                ...(attbArray as { [key: string]: string }),
              },
            },
          }),
        );
        break;
      case 'options':
        const removedOptionsKeys = [...optionsKeys];
        removedOptionsKeys.splice(Number(item), 1);
        const removedOptionsRows = [...optionsRows];
        removedOptionsRows.splice(Number(item), 1);
        setOptionsKeys(removedOptionsKeys);
        setOptionsRows(removedOptionsRows);
        const formatttedOptionsResult = formatedObjectOptions(
          removedOptionsKeys,
          removedOptionsRows,
        );
        dispatch(
          setNewCommandInput({
            ...(commandInput as CommandInput),
            options: formatttedOptionsResult,
          }),
        );
        break;
    }
  };

  const handleOnAccountChange = (companyId: string): void => {
    handleValueChange('company_id', companyId);
  };

  return (
    <Grid container direction="column" spacing={3} className="p-9 pt-6">
      <Header
        model="command"
        isCreatePage={true}
        goBack={goBackUrl ? goBackUrl : 'commands'}
        goBackLabel={goBackLabel || `Commands`}
      />

      <Grid item xs={12}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={8}>
            <Paper className="shadow p-8">
              <Box className={clsx('mb-6', classes.titleContainer)}>
                <Typography
                  data-cy="create-command-title"
                  variant="h5"
                  className={classes.title}
                >
                  Create a new command
                </Typography>
              </Box>
              <CommandForm
                invalidSenderJson={invalidSenderJson}
                invalidOptionJson={invalidOptionJson}
                newCommand={commandInput as CommandInput}
                onInputChange={handleValueChange}
                onAddRow={handleAddRow}
                onRemoveRow={handleRemoveRow}
                onDynamicChange={handleDynamicChange}
                shellCommand={shellCommand}
              />
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Publish
              label="account"
              submitting={submitting}
              companyId={commandInput?.company_id as string}
              onChangeAccount={handleOnAccountChange}
              onSubmit={handlePublishSubmit}
              enableSubmit={enableSubmit && !submitting}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateCommandPage;
