import React, { useCallback, useState } from 'react';
import { Grid, IconButton, InputAdornment, MenuItem } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { debounce } from 'lodash';

import TextInput from '../../components/TextInput';
import { SEARCH_LETTERS_QUANTITY } from '../../app/constants';
import SelectInput from '../../components/SelectInput';

interface SettingsFiltersProps {
  settingNameFilter: string;
  settingTypeFilter?: string;
  hideTypeFilter?: boolean;
  setSettingNameFilter: React.Dispatch<React.SetStateAction<string>>;
  setSettingTypeFilter?: React.Dispatch<React.SetStateAction<string>>;
}

const SettingsFilters: React.FC<SettingsFiltersProps> = ({
  settingNameFilter,
  settingTypeFilter,
  hideTypeFilter = false,
  setSettingTypeFilter,
  setSettingNameFilter,
}) => {
  const [searchInputValue, setSearchInputValue] = useState(settingNameFilter);

  const handleCallbackProp = (value: string): void => {
    setSettingNameFilter(value);
  };

  const debouncedChangeHandler = useCallback(
    debounce(handleCallbackProp, 1000),
    [],
  );

  const handleInputChange = (prop: string, value: string | number): void => {
    const realValue = value as string;
    setSearchInputValue(realValue);
    debouncedChangeHandler.cancel();
    if (realValue.length >= SEARCH_LETTERS_QUANTITY || realValue.length === 0) {
      debouncedChangeHandler(realValue);
    }
  };

  const handleClickClear = (): void => {
    setSearchInputValue('');
    handleCallbackProp('');
  };

  const handleMouseDown = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();
  };

  const onChangeSearchType = (_prop: string, value: string): void => {
    if (setSettingTypeFilter) {
      setSettingTypeFilter(value);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={hideTypeFilter ? 12 : 9}>
        <TextInput
          placeholder="Search setting by name"
          prop="setting-name-filter"
          value={searchInputValue}
          onInputChange={handleInputChange}
          helperText={`Type in at least ${SEARCH_LETTERS_QUANTITY} characters to filter`}
          endAdornment={
            <InputAdornment data-cy="clear-name-filter" position="end">
              {searchInputValue && (
                <IconButton
                  aria-label="clear-name-filter"
                  onClick={handleClickClear}
                  onMouseDown={handleMouseDown}
                >
                  <HighlightOffIcon />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
      </Grid>
      {!hideTypeFilter && (
        <Grid item xs={12} md={3}>
          <SelectInput
            prop="setting-type-filter"
            value={settingTypeFilter}
            onSelectChange={onChangeSearchType}
            fullWidth={false}
            options={[
              <MenuItem dense key="all" value="all" className="m-4 p-2">
                All
              </MenuItem>,
              <MenuItem dense key="created" value="created" className="m-4 p-2">
                Created
              </MenuItem>,
              <MenuItem
                dense
                key="reported"
                value="reported"
                className="m-4 p-2"
              >
                Reported
              </MenuItem>,
            ]}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SettingsFilters;
