import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Paper } from '@mui/material';
import {
  Command,
  DeviceType,
  PaginationFilter,
  Setting,
  SettingFilters,
  SettingInput,
  Settings,
} from '@edgeiq/edgeiq-api-js';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import {
  setNewSettingInput,
  newSettingInitialState,
} from '../../../redux/reducers/settings.reducer';
import { defaultItemsPerPage, errorHighlight } from '../../../app/constants';
import SettingsList from '../../../containers/SettingsList';
import SettingsFilters from '../../../containers/SettingsFilters';

interface ConfigurationSettingsProp {
  deviceTypes: DeviceType[];
  sendCommands: Command[];
}

const ConfigurationSettings: React.FC<ConfigurationSettingsProp> = ({
  deviceTypes,
  sendCommands,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const configuration = useAppSelector(
    (state: RootState) => state.configurations.newConfiguration,
  );
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState<Setting[]>([]);
  const [settingNameFilter, setSettingNameFilter] = useState('');
  const [settingTypeFilter, setSettingTypeFilter] = useState('all');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loadingMore, setLoadingMore] = useState(false);

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const getSettings = (pageNumber: number, addPage = false): void => {
    if (configuration && configuration._id) {
      if (!addPage) {
        setLoading(true);
      }
      const pagination: PaginationFilter = {
        itemsPerPage: defaultItemsPerPage,
        order_by: 'name,-version,updated_at',
        page: pageNumber,
      };
      const filters: SettingFilters = {
        configuration_id: {
          operator: 'eq',
          value: configuration._id as string,
        },
      };
      if (settingNameFilter) {
        filters.name = {
          operator: 'like',
          value: settingNameFilter,
        };
      }
      if (settingTypeFilter && settingTypeFilter !== 'all') {
        filters.is_self_reported = {
          operator: 'eq',
          value: settingTypeFilter === 'reported',
        };
      }
      Settings.list(filters, pagination)
        .then((result) => {
          const newSettings = addPage
            ? [...settings, ...result.settings]
            : result.settings;
          setSettings(newSettings);
          setTotalAndPage(result.pagination.total, addPage);
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
          setLoadingMore(false);
        });
    }
  };

  useEffect(() => {
    if (!loading) {
      getSettings(1);
    }
  }, [settingNameFilter, settingTypeFilter]);

  const handleAddSettings = (): void => {
    dispatch(
      setNewSettingInput({
        ...newSettingInitialState,
      } as SettingInput),
    );
    navigate(`/configuration/${configuration?._id}/new-settings`);
  };

  const loadMore = (): void => {
    setLoadingMore(true);
    getSettings(page + 1, true);
  };

  return (
    <Grid container>
      <Grid component={Paper} item xs={12} className="p-4 shadow">
        <Box className="mb-7">
          <Button
            data-cy="tab-page-contained-button"
            variant="contained"
            size="large"
            className="mb-"
            onClick={handleAddSettings}
          >
            Create New Settings
          </Button>
        </Box>
        <Box className="mb-4">
          <SettingsFilters
            settingNameFilter={settingNameFilter}
            settingTypeFilter={settingTypeFilter}
            setSettingNameFilter={setSettingNameFilter}
            setSettingTypeFilter={setSettingTypeFilter}
          />
        </Box>

        <SettingsList
          attachedSettings={false}
          unlinkableSettings={false}
          configuration={configuration}
          settings={settings}
          loading={loading}
          loadingMore={loadingMore}
          total={total}
          deviceTypes={deviceTypes}
          sendCommands={sendCommands}
          reloadSettings={getSettings}
          loadMore={loadMore}
        />
      </Grid>
    </Grid>
  );
};

export default ConfigurationSettings;
