import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoIcon: {
      '&:hover': {
        cursor: 'help',
      },
      color: theme.palette.grey[200],
      verticalAlign: 'middle',
    },
  }),
);

export default useStyles;
