import React, { Dispatch, useMemo } from 'react';
import { DeviceType } from '@edgeiq/edgeiq-api-js';

import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { MetadataProp, SortingOption } from '../../../models/common';
import {
  activationStatusLabel,
  devicesFiltersTabsLabels,
  healthStatusLabel,
  heartbeatStatus,
  heartBeatStatusLabel,
  METADATA_KEY,
} from '../../../app/constants';
import AdvancedSelect from '../../../components/AdvancedSelect';
import VerticalTabs from '../../../components/VerticalTabs';
import AccountsExplorer from '../../../containers/AccountsExplorer';
import DeviceMetadataFilter from './ActiveDeviceMetadataFilter';

interface ActiveDevicesMoreFiltersProps {
  deviceTypes: DeviceType[];
  filters: {
    [key: string]: string;
  };
  onFiltersChange: (
    prop: string,
    value: string | { [key: string]: string },
  ) => void;
  updateLoading: Dispatch<React.SetStateAction<boolean>>;
}

const ActiveDevicesMoreFilters: React.FC<ActiveDevicesMoreFiltersProps> = ({
  deviceTypes,
  filters,
  onFiltersChange,
}) => {
  const { userCompanies } = useAppSelector((state: RootState) => state.user);

  const accountsSelected: string[] = filters.company_id
    ? filters.company_id.split('|')
    : [];

  const ancestorSelected = filters.ancestor_company_id
    ? filters.ancestor_company_id
    : '';

  const deviceTypesSelected: string[] = filters.device_type_id
    ? filters.device_type_id.split('|')
    : [];
  const deviceTypesOptions: SortingOption[] = deviceTypes.map((deviceType) => {
    return {
      label: deviceType.name,
      value: deviceType._id,
    };
  });

  const heartbeatSelected: string[] = filters.heartbeat_status
    ? filters.heartbeat_status.split('|')
    : [];
  const heartbeatStatusOptions: SortingOption[] = heartbeatStatus.map(
    (item) => {
      return {
        label: heartBeatStatusLabel[item],
        value: item,
      };
    },
  );

  const allTags: string[] = useMemo(() => {
    const setOfTags = new Set<string>();

    userCompanies.forEach((company) => {
      if (company.device_tags?.length) {
        for (const tag of company.device_tags) {
          setOfTags.add(tag);
        }
      }
    });

    return Array.from(setOfTags);
  }, [userCompanies]);

  const tagsSelected: string[] = filters.tags ? filters.tags.split('|') : [];
  const tagsOptions: SortingOption[] = allTags.map((item) => {
    return {
      label: item,
      value: item,
    };
  });

  const healthStatusSelected: string[] = filters.health_status
    ? filters.health_status.split('|')
    : [];
  const healthStatusOptions: SortingOption[] = Object.keys(
    healthStatusLabel,
  ).map((item) => {
    return {
      label: healthStatusLabel[item],
      value: item,
    };
  });

  const activationStatusSelected: string[] = filters.activation_status
    ? filters.activation_status.split('|')
    : [];
  const activationStatusOptions: SortingOption[] = Object.keys(
    activationStatusLabel,
  ).map((item) => {
    return {
      label: activationStatusLabel[item],
      value: item,
    };
  });

  const metadataFilters: MetadataProp[] = [];
  for (const key in filters) {
    if (Object.prototype.hasOwnProperty.call(filters, key)) {
      const index = key.indexOf(METADATA_KEY);
      if (index !== -1) {
        metadataFilters.push({
          key: key.slice(METADATA_KEY.length, key.length),
          value: filters[key],
        });
      }
    }
  }

  const handleChooseAccount = (id: string): void => {
    const newValue = accountsSelected.includes(id)
      ? accountsSelected.filter((account) => account !== id)
      : [...accountsSelected, id];
    onFiltersChange('company_id', newValue.join('|'));
  };

  const handleChooseAncestor = (id: string): void => {
    onFiltersChange('ancestor_company_id', id);
  };

  return (
    <>
      <VerticalTabs
        defaultTab="accounts"
        tabsLabel={devicesFiltersTabsLabels}
        contentFullHeight={true}
        /* eslint sort-keys: 0 */
        tabs={{
          accounts: (
            <AccountsExplorer
              selectedAccountsIds={accountsSelected}
              ancestorId={ancestorSelected}
              onChoosingAccount={handleChooseAccount}
              onChoosingAncestor={handleChooseAncestor}
            />
          ),
          deviceProfiles: (
            <AdvancedSelect
              searchPlaceholder="Search Device Profile"
              prop="device_type_id"
              options={deviceTypesOptions}
              value={deviceTypesSelected}
              onOptionsChange={onFiltersChange}
            />
          ),
          heartbeat: (
            <AdvancedSelect
              prop="heartbeat_status"
              options={heartbeatStatusOptions}
              value={heartbeatSelected}
              onOptionsChange={onFiltersChange}
            />
          ),
          tags: (
            <AdvancedSelect
              prop="tags"
              options={tagsOptions}
              value={tagsSelected}
              onOptionsChange={onFiltersChange}
            />
          ),
          healthStatus: (
            <AdvancedSelect
              prop="health_status"
              options={healthStatusOptions}
              value={healthStatusSelected}
              onOptionsChange={onFiltersChange}
            />
          ),
          activationStatus: (
            <AdvancedSelect
              prop="activation_status"
              options={activationStatusOptions}
              value={activationStatusSelected}
              onOptionsChange={onFiltersChange}
            />
          ),
          metadata: (
            <DeviceMetadataFilter
              value={metadataFilters}
              onFiltersChange={onFiltersChange}
            />
          ),
        }}
        padding={false}
      />
    </>
  );
};

export default ActiveDevicesMoreFilters;
