import React, { useCallback, useState } from 'react';

import { Box } from '@mui/system';
import { Button, Grid, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import debounce from 'lodash/debounce';

import { MetadataProp } from '../../../models/common';
import TextInput from '../../../components/TextInput';

interface DeviceMetadataFilterProps {
  value: MetadataProp[];
  onFiltersChange: (
    prop: string,
    value: string | { [key: string]: string },
  ) => void;
}

const DeviceMetadataFilter: React.FC<DeviceMetadataFilterProps> = ({
  value,
  onFiltersChange,
}) => {
  const [metadataItems, setMetadataItems] = useState<MetadataProp[]>(value);

  const parseMetadataList = (
    metadata: MetadataProp[],
  ): {
    [key: string]: string;
  } => {
    const parsed: { [key: string]: string } = {};
    for (let i = 0; i < metadata.length; i++) {
      if (!parsed[`metadata.${metadata[i].key}`]) {
        parsed[`metadata.${metadata[i].key}`] = metadata[i].value;
      }
    }
    return parsed;
  };

  const updateMetadataItems = (metadata: MetadataProp[]): void => {
    onFiltersChange('metadata', parseMetadataList(metadata));
  };

  const updateMetadataDebounce = useCallback(
    debounce(updateMetadataItems, 300),
    [],
  );

  const handleChange = (
    index: number,
    key: string,
    changeValue: string,
  ): void => {
    const auxArray = [...metadataItems];
    if (key === 'key') {
      auxArray[index].key = changeValue;
    } else {
      auxArray[index].value = changeValue;
    }
    setMetadataItems(auxArray);
    updateMetadataDebounce.cancel();
    updateMetadataDebounce(auxArray);
  };

  const handleRemove = (index: number): void => {
    const auxArray = [...metadataItems];
    auxArray.splice(index, 1);
    setMetadataItems(auxArray);
    onFiltersChange('metadata', parseMetadataList(auxArray));
  };

  const addNewMetadata = (): void => {
    setMetadataItems([...metadataItems, { key: '', value: '' }]);
  };

  const handleInputChange =
    (index: number, prop: string) =>
    (_name: string, changeValue: string | number): void => {
      handleChange(index, prop, changeValue as string);
    };

  return (
    <Box>
      {metadataItems.map((item, index) => (
        <Grid
          key={index}
          container
          direction="row"
          spacing={2}
          className="mt-2"
          alignItems="center"
        >
          <Grid item xs={5}>
            <TextInput
              label="Key"
              prop="key"
              value={item.key as string}
              onInputChange={handleInputChange(index, 'key')}
            />
          </Grid>
          <Grid item xs={5}>
            <TextInput
              label="Value"
              prop="value"
              value={item.value as string}
              onInputChange={handleInputChange(index, 'value')}
            />
          </Grid>
          <Grid item xs={2} className="mt-6">
            <IconButton onClick={(): void => handleRemove(index)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button
        variant="outlined"
        size="medium"
        className="mt-4"
        onClick={addNewMetadata}
        startIcon={<AddIcon />}
      >
        Add new
      </Button>
    </Box>
  );
};

export default DeviceMetadataFilter;
