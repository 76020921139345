import React, { useState, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { CompareArrows as CompareIcon } from '@mui/icons-material';
import {
  Configuration,
  Devices,
  DeviceTypes,
  PaginationFilter,
  Setting,
  SettingFilters,
} from '@edgeiq/edgeiq-api-js';
import { diff as DiffEditor } from 'react-ace';

import { RootState } from '../../../redux/store';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { dispatchError, getFetchError } from '../../../helpers/utils';
import { defaultItemsPerPage } from '../../../app/constants';
import SideTabs from '../../../components/SideTabs';
import SettingsList from '../../../containers/SettingsList';
import SettingApplicationList from '../../../containers/SettingApplicationList';
import SettingsFilters from '../../../containers/SettingsFilters';
import DeviceSettingType from './DeviceSettingType';

const DeviceSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const errorDispatcher = dispatchError(dispatch);
  const editableDevice = useAppSelector(
    (state: RootState) => state.devices.newDevice,
  );
  const [loading, setLoading] = useState(false);
  const [configLoading, setConfigLoading] = useState(true);
  const [loadingDeviceSettings, setLoadingDeviceSettings] = useState(false);
  const [loadingDeviceTypeSettings, setLoadingDeviceTypeSettings] =
    useState(false);
  const [configurations, setConfigurations] = useState<Configuration[]>([]);
  const [chosenConfig, setChosenConfig] = useState<Configuration>();
  const [tabIndex, setTabIndex] = useState(0);
  const [deviceTypeSettings, setDeviceTyepSettings] = useState<Setting[]>([]);
  const [settings, setSettings] = useState<Setting[]>([]);
  const [compareSettings, setCompareSettings] = useState<Setting[]>([]);
  const [tabs, setTabs] = useState<{
    [key: string]: JSX.Element;
  }>({});
  const [showCompare, setShowCompare] = useState(false);
  const [devSettingNameFilter, setDevSettingNameFilter] = useState('');
  const [devSettingTypeFilter, setDevSettingTypeFilter] = useState('all');
  const [pageDevSettings, setPageDevSettings] = useState(1);
  const [totalDevSettings, setTotalDevSettings] = useState(0);
  const [loadingMoreDevSettings, setLoadingMoreDevSettings] = useState(false);

  const [devTypeSettingNameFilter, setDevTypeSettingNameFilter] = useState('');
  const [pageDevTypeSettings, setPageDevTypeSettings] = useState(1);
  const [totalDevTypeSettings, setTotalDevTypeSettings] = useState(0);
  const [loadingMoreDevTypeSettings, setLoadingMoreDevTypeSettings] =
    useState(false);

  useEffect(() => {
    if (configurations.length) {
      setChosenConfig(configurations[0]);
    }
  }, [configurations]);

  useEffect(() => {
    if (editableDevice) {
      setLoading(true);
      DeviceTypes.getConfigurations(editableDevice.device_type_id)
        .then((res) => {
          setConfigurations(res);
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getFetchError('configurations.'),
          );
        })
        .finally(() => {
          setLoading(false);
          setConfigLoading(false);
        });
    }
  }, [editableDevice]);

  useEffect(() => {
    getTabs();
  }, [
    compareSettings,
    settings,
    deviceTypeSettings,
    loadingDeviceSettings,
    loadingDeviceTypeSettings,
    loadingMoreDevSettings,
    loadingMoreDevTypeSettings,
    devSettingNameFilter,
    devSettingTypeFilter,
    devTypeSettingNameFilter,
  ]);

  const setTotalAndPageDevSettings = (
    newTotal: number,
    addPage = false,
  ): void => {
    setTotalDevSettings(newTotal);
    if (addPage) {
      setPageDevSettings(pageDevSettings + 1);
    }
  };

  const getDeviceSettings = (pageNumber: number, addPage = false): void => {
    if (editableDevice && chosenConfig) {
      if (!addPage) {
        setLoadingDeviceSettings(true);
      }
      const pagination: PaginationFilter = {
        itemsPerPage: defaultItemsPerPage,
        order_by: 'name,-version,updated_at',
        page: pageNumber,
      };
      const filters: SettingFilters = {
        configuration_id: {
          operator: 'eq',
          value: chosenConfig._id as string,
        },
      };
      if (devSettingNameFilter) {
        filters.name = {
          operator: 'like',
          value: devSettingNameFilter,
        };
      }
      if (devSettingTypeFilter && devSettingTypeFilter !== 'all') {
        filters.is_self_reported = {
          operator: 'eq',
          value: devSettingTypeFilter === 'reported',
        };
      }
      Devices.getSettings(editableDevice._id, filters, pagination)
        .then((result) => {
          const newSettings = addPage
            ? [...settings, ...result.settings]
            : result.settings;
          setSettings(newSettings);
          setTotalAndPageDevSettings(result.pagination.total, addPage);
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getFetchError('device settings.'),
          );
        })
        .finally(() => {
          setLoadingDeviceSettings(false);
          setLoadingMoreDevSettings(false);
        });
    }
  };

  const setTotalAndPageDevTypeSettings = (
    newTotal: number,
    addPage = false,
  ): void => {
    setTotalDevTypeSettings(newTotal);
    if (addPage) {
      setPageDevTypeSettings(pageDevTypeSettings + 1);
    }
  };

  const getDeviceTypeSettings = (pageNumber: number, addPage = false): void => {
    if (editableDevice && chosenConfig) {
      if (!addPage) {
        setLoadingDeviceTypeSettings(true);
      }
      const pagination: PaginationFilter = {
        itemsPerPage: defaultItemsPerPage,
        order_by: 'name,-version,updated_at',
        page: pageNumber,
      };
      const filters: SettingFilters = {
        configuration_id: {
          operator: 'eq',
          value: chosenConfig._id as string,
        },
      };
      if (devSettingNameFilter) {
        filters.name = {
          operator: 'like',
          value: devTypeSettingNameFilter,
        };
      }
      DeviceTypes.getSettings(
        editableDevice.device_type_id,
        filters,
        pagination,
      )
        .then((result) => {
          const newSettings = addPage
            ? [...deviceTypeSettings, ...result.settings]
            : result.settings;
          setDeviceTyepSettings(newSettings);
          setTotalAndPageDevTypeSettings(result.pagination.total, addPage);
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getFetchError('device profile settings.'),
          );
        })
        .finally(() => {
          setLoadingDeviceTypeSettings(false);
          setLoadingMoreDevTypeSettings(false);
        });
    }
  };

  useEffect(() => {
    getDeviceSettings(1);
    getDeviceTypeSettings(1);
  }, [chosenConfig]);

  useEffect(() => {
    getDeviceSettings(1);
  }, [devSettingNameFilter, devSettingTypeFilter]);

  useEffect(() => {
    getDeviceTypeSettings(1);
  }, [devTypeSettingNameFilter]);

  // This is to check if in the page we have multiple settings, with different versions, to know
  // if we have to show or not the compare button.
  useEffect(() => {
    const allSettings = [...settings, ...deviceTypeSettings];
    let differentSettings = false;
    for (let i = 0; i < settings.length - 1; i++) {
      const setting = allSettings[i];
      for (let j = i + 1; allSettings.length; j++) {
        const nextSetting = allSettings[j];
        if (
          setting._id !== nextSetting._id ||
          setting.version !== nextSetting.version
        ) {
          differentSettings = true;
          break;
        }
      }
      if (differentSettings) {
        setShowCompare(true);
        break;
      }
    }
  }, [settings, deviceTypeSettings]);

  const handleChangeConfig = (
    _event: React.SyntheticEvent,
    newValue: number,
  ): void => {
    setTabIndex(newValue);
    setChosenConfig(configurations[newValue]);
  };

  const handleChoosingSettingToCompare = (setting: Setting): void => {
    const newSettings = [...compareSettings];
    const settingIndex = newSettings
      .map((s) => `${s._id}_${s.version}`)
      .indexOf(`${setting._id}_${setting.version}`);
    if (settingIndex !== -1) {
      // If the setting has been chosen, then remove it
      newSettings.splice(settingIndex, 1);
    } else {
      newSettings.push(setting);
    }
    setCompareSettings(newSettings);
  };

  const loadMoreDevSettings = (): void => {
    setLoadingMoreDevSettings(true);
    getDeviceSettings(pageDevSettings + 1, true);
  };

  const loadMoreDevTypeSettings = (): void => {
    setLoadingMoreDevTypeSettings(true);
    getDeviceTypeSettings(pageDevSettings + 1, true);
  };

  const getTabs = (): void => {
    /* eslint sort-keys: 0 */
    const realTabs: {
      [key: string]: JSX.Element;
    } = {
      linked_settings: (
        <Grid container>
          <Grid item xs={12} className="mb-3">
            <Typography variant="h6">Attached to Device Profile:</Typography>
          </Grid>
          <Grid item xs={12} className="mb-3">
            <SettingsFilters
              hideTypeFilter={true}
              settingNameFilter={devTypeSettingNameFilter}
              setSettingNameFilter={setDevTypeSettingNameFilter}
            />
          </Grid>
          <Grid item xs={12}>
            <SettingsList
              attachedSettings={true}
              unlinkableSettings={false}
              device={editableDevice}
              configuration={chosenConfig}
              settings={deviceTypeSettings}
              loading={loadingDeviceTypeSettings}
              settingsToCompare={compareSettings}
              showCompareButton={showCompare}
              loadingMore={loadingMoreDevTypeSettings}
              total={totalDevTypeSettings}
              onChoosingSetting={handleChoosingSettingToCompare}
              reloadSettings={getDeviceTypeSettings}
              loadMore={loadMoreDevTypeSettings}
            />
          </Grid>
          <Grid item xs={12} className="mb-3">
            <Typography variant="h6">Attached to Device:</Typography>
          </Grid>
          <Grid item xs={12} className="mb-3">
            <SettingsFilters
              settingNameFilter={devSettingNameFilter}
              settingTypeFilter={devSettingTypeFilter}
              setSettingNameFilter={setDevSettingNameFilter}
              setSettingTypeFilter={setDevSettingTypeFilter}
            />
          </Grid>
          <Grid item xs={12}>
            <SettingsList
              attachedSettings={true}
              unlinkableSettings={true}
              device={editableDevice}
              configuration={chosenConfig}
              settings={settings}
              loading={loadingDeviceSettings}
              settingsToCompare={compareSettings}
              showCompareButton={showCompare}
              loadingMore={loadingMoreDevSettings}
              total={totalDevSettings}
              onChoosingSetting={handleChoosingSettingToCompare}
              reloadSettings={getDeviceSettings}
              loadMore={loadMoreDevSettings}
            />
          </Grid>
        </Grid>
      ),
      last_reported_settings: (
        <DeviceSettingType
          device={editableDevice}
          configuration={chosenConfig}
          type="reported"
        />
      ),
      last_sent_settings: (
        <DeviceSettingType
          device={editableDevice}
          configuration={chosenConfig}
          type="sent"
        />
      ),
    };

    if (compareSettings.length === 2) {
      let value1 = '';
      let value2 = '';
      try {
        value1 = JSON.stringify(compareSettings[0].values, null, 2);
        value2 = JSON.stringify(compareSettings[1].values, null, 2);
      } catch (error) {
        console.info(error);
      }
      realTabs.compare_settings = (
        <>
          <div className="mb-6 d-flex">
            <Typography variant="h5" className="mr-3">
              {`${compareSettings[0].name} - v${compareSettings[0].version}`}
            </Typography>
            <CompareIcon fontSize="small" />
            <Typography variant="h5" className="ml-3">
              {`${compareSettings[1].name} - v${compareSettings[1].version}`}
            </Typography>
          </div>
          <div className="w-100 scrollbar">
            <DiffEditor
              value={[value1, value2]}
              height="400px"
              width="1000px"
              mode="json"
            />
          </div>
        </>
      );
    }

    setTabs(realTabs);
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12} sm={9}>
        <Box>
          <Paper className="shadow p-6">
            {loading ? (
              <Grid container className="loading-container">
                <CircularProgress size={75} thickness={5} />
              </Grid>
            ) : (
              <Box>
                <Grid container direction="column">
                  <Grid item xs={12} className="d-flex flex-items-center">
                    <Typography variant="h5" className="mr-3">
                      Configurations:
                    </Typography>
                    <Tabs
                      value={tabIndex}
                      onChange={handleChangeConfig}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      {configurations.map((configuration) => (
                        <Tab
                          key={configuration._id}
                          label={configuration.name}
                        />
                      ))}
                    </Tabs>
                  </Grid>
                  <Grid item xs={12} className="mt-6 w-100">
                    <SideTabs
                      defaultTab="linked_settings"
                      rightPadding="pr-2"
                      leftPadding="pl-4"
                      tabs={tabs}
                      hScrollableContent={true}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Paper>
        </Box>
      </Grid>
      <Grid item xs={3}>
        {(chosenConfig || (!configLoading && !configurations.length)) &&
          editableDevice && (
            <SettingApplicationList
              configuration={chosenConfig}
              device={editableDevice}
              settingsToCompare={compareSettings}
              showCompareButton={showCompare}
              onChoosingSetting={handleChoosingSettingToCompare}
            />
          )}
      </Grid>
    </Grid>
  );
};

export default DeviceSettings;
