import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import isEqual from 'lodash.isequal';
import cloneDeep from 'lodash.clonedeep';
import {
  Devices,
  DeviceTypes,
  Device,
  DeviceType,
} from '@edgeiq/edgeiq-api-js';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  getDeviceSelector,
  setActualDevice,
} from '../../redux/reducers/devices.reducer';
import { getDeviceTypeSelector } from '../../redux/reducers/deviceTypes.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { setFilters } from '../../redux/reducers/filters.reducer';
import { useFetchCompany } from '../../hooks/useFetchCompany';
import { dispatchError, getPageHash } from '../../helpers/utils';
import {
  heartbeatColorThemeMap,
  deviceDetailsTabsLabel,
  errorHighlight,
  GATEWAY_ROLE,
  LWM2M_TYPE,
  ENDPOINT_ROLE,
  GATEWAY_TYPE,
  DETAILS_DEFAULT_TAB,
} from '../../app/constants';
import { LocationState } from '../../models/common';
import { formatHeartbeat, getHeartbeatSeconds } from '../../helpers/heartbeat';
import Header from '../../containers/HeaderWithActionButton';
import ContentHeader from '../../components/ContentHeader';
import VerticalTabs from '../../components/VerticalTabs';
import FooterBar from '../../components/FooterBar';
import { IssueCommandDrawer } from '../../containers/RightDrawer';
import MqttPasswordConfirmationDialog from '../../containers/MqttPasswordConfirmationDialog';
import TypographyWithCopy from '../../components/TypographyWithCopy';
import DeviceDetails from './deviceDetails';
import DeviceIngestors from './deviceIngestors';
import DevicePolicies from './devicePolicies';
import DeviceCommands from './deviceCommands';
import DeviceSoftwareUpdates from './deviceSoftwareUpdates';
import DeviceMetadata from './deviceMetadata';
import DeviceRelations from './deviceEndpoints';
import DeviceLogs from './deviceLogs';
import DeviceLwm2mDetail from './deviceLwm2mDetail';
import DeviceFiles from './deviceFiles';
import DeviceRemoteTerminal from './deviceTerminal';
import DeviceSettings from './deviceSettings';
import useStyles from './styles';

const DeviceContent: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { id } = useParams<string>();
  const classes = useStyles();
  const goBackLabel = (location.state as LocationState)?.goBackLabel;
  const goBackUrl = (location.state as LocationState)?.goBackUrl;
  const stateFilters = useAppSelector((state: RootState) => state.filters);
  const filters = stateFilters.devices.filters;
  const deviceData = useAppSelector((state: RootState) =>
    getDeviceSelector(state.devices, id),
  );
  const _stateDevice = useAppSelector(
    (state: RootState) => state.devices.device,
  );
  const deviceTypesState = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const [deviceTypeData, setDeviceTypeData] = useState<DeviceType | undefined>(
    getDeviceTypeSelector(deviceTypesState, deviceData?.device_type_id),
  );
  const errorDispatcher = dispatchError(useAppDispatch);
  const [deviceCompany] = useFetchCompany(
    deviceData?.company_id,
    errorDispatcher,
  );
  const [deviceCompanyParent] = useFetchCompany(
    deviceCompany?.company_id,
    errorDispatcher,
  );
  const newDevice = useAppSelector(
    (state: RootState) => state.devices.newDevice,
  );
  const [loading, setLoading] = useState(false);
  const [heartbeatPeriod, setHeartbeatPeriod] = useState<number>();
  const [selectedHearbeatUnit, setSelectedHearbeatUnit] =
    useState<string>('seconds');
  const [initialHeartBeatValues, setInitialHeartBeatValue] = useState<{
    heartbeat_period_changed: number;
    heartbeat_time: string;
  }>();

  const [openGatewayCommand, setOpenGatewayCommand] = useState(false);
  const [activeTab, setActiveTab] = useState(DETAILS_DEFAULT_TAB);
  const [ActionDialogOpen, setActionDialogOpen] = useState(false);
  const [modifiedMqttPassword, setModifiedMqttPassword] = useState<string>('');

  const handleConfirm = (): void => {
    setActionDialogOpen(false);
    setModifiedMqttPassword('');
  };

  const handleOpenGatewayCommand = (): void => {
    setOpenGatewayCommand(true);
  };
  const handleCloseGatewayCommand = (): void => {
    setOpenGatewayCommand(false);
  };
  const handleGatewayCommandsCallback = (): void => {
    handleCloseGatewayCommand();
  };

  const getDeviceType = (deviceTypeId: string): void => {
    DeviceTypes.getOneById(deviceTypeId)
      .then((deviceTypeResponse) => {
        setDeviceTypeData(deviceTypeResponse);
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      });
  };

  const setDevicePageData = (device: Device): void => {
    const result = formatHeartbeat(device);
    setInitialHeartBeatValue(result);
    setSelectedHearbeatUnit(result.heartbeat_time);
    setHeartbeatPeriod(result.heartbeat_period_changed);
    getDeviceType(device.device_type_id);
  };

  useEffect(() => {
    if (modifiedMqttPassword && modifiedMqttPassword != '') {
      setActionDialogOpen(true);
    }
    if (deviceData && deviceData._id === id) {
      dispatch(setActualDevice(deviceData));
      setDevicePageData(deviceData);
    } else if (id) {
      Devices.getOneById(id)
        .then((response) => {
          dispatch(setActualDevice(response));
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    }
  }, [deviceData, id]);

  useEffect(() => {
    setActiveTab(
      getPageHash(location.hash, DETAILS_DEFAULT_TAB, deviceDetailsTabsLabel),
    );
  }, [location.hash]);

  const handleDeleteDevice = (): void => {
    if (!deviceData) {
      return;
    }
    setLoading(true);
    Devices.delete(deviceData._id)
      .then(() => {
        dispatch(
          setAlert({
            highlight: 'Delete device',
            message: 'Device successfully deleted.',
            type: 'success',
          }),
        );
        navigate('/devices');
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveChanges = (): void => {
    const payload = { ...newDevice };
    if (heartbeatPeriod) {
      payload.heartbeat_period = getHeartbeatSeconds(
        selectedHearbeatUnit,
        heartbeatPeriod,
      );
    }
    if (payload !== deviceData) {
      setModifiedMqttPassword(payload.mqtt_password ?? '');
      setLoading(true);
      Devices.update(payload as Device)
        .then((response) => {
          dispatch(setActualDevice(response));
          dispatch(
            setAlert({
              highlight: 'Update device',
              message: 'Device successfully updated.',
              type: 'success',
            }),
          );
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const goToItem = (type: string, itemId?: string) => (): void => {
    let url = '';
    let tab = '';
    switch (type) {
      case 'ingestor':
        url = `data-management/${
          itemId ? `${type}/${itemId}` : 'new-ingestor'
        }`;
        tab = 'ingestors';
        break;

      case 'policy':
        url = `${itemId ? `${type}/${itemId}` : 'new-policy'}`;
        tab = 'policies';
        break;

      case 'command':
        url = `${itemId ? `${type}/${itemId}` : 'new-command'}`;
        tab = 'commands';
        break;

      case 'software-update':
        url = `${itemId ? `${type}/${itemId}` : 'software-update'}`;
        tab = 'software-updates';
        break;

      default:
        break;
    }
    navigate(`/${url}`, {
      state: {
        goBackLabel: `Device: ${deviceData?.name}`,
        goBackUrl: `device/${deviceData?._id}#${tab}`,
      },
    });
  };

  /**
   * PLEASE KEEP THE ORDER THE WAY IT IS AND IGNORE THE LINTING ORDER
   */
  const getTabs = (): {
    [key: string]: JSX.Element;
  } => {
    /* eslint sort-keys: 0 */
    const tabs: {
      [key: string]: JSX.Element;
    } = {
      details: (
        <DeviceDetails
          setHeartbeatPeriod={setHeartbeatPeriod}
          heartbeatPeriod={heartbeatPeriod}
          deviceType={deviceTypeData}
          handleOpenGatewayCommand={handleOpenGatewayCommand}
          setSelectedHearbeatUnit={setSelectedHearbeatUnit}
          selectedHearbeatUnit={selectedHearbeatUnit}
        />
      ),
      ingestors: <DeviceIngestors goToItem={goToItem} />,
      policies: <DevicePolicies goToItem={goToItem} />,
      logs: <DeviceLogs />,
      commands: <DeviceCommands goToItem={goToItem} />,
      settings: <DeviceSettings />,
      software_updates: <DeviceSoftwareUpdates goToItem={goToItem} />,
    };

    if (deviceTypeData?.role === GATEWAY_ROLE) {
      tabs.relations = <DeviceRelations deviceType={deviceTypeData} />;
    }

    if (deviceTypeData?.role === ENDPOINT_ROLE) {
      tabs.parentDevice = <DeviceRelations deviceType={deviceTypeData} />;
    }

    if (deviceTypeData?.type === LWM2M_TYPE) {
      tabs.lwm2mDetails = <DeviceLwm2mDetail deviceType={deviceTypeData} />;
    }

    if (
      deviceTypeData?.role === GATEWAY_ROLE &&
      deviceTypeData.type === GATEWAY_TYPE
    ) {
      tabs.remoteTerminal = (
        <DeviceRemoteTerminal deviceType={deviceTypeData} />
      );
    }

    tabs.files = <DeviceFiles />;
    tabs.metadata = <DeviceMetadata />;

    return tabs;
  };

  const handleParentCompanyFilter = (parentCompany: boolean) => (): void => {
    if (parentCompany && deviceCompanyParent) {
      dispatch(
        setFilters(
          {
            ...filters,
            ancestor_company_id: deviceCompanyParent._id,
            company_id: '',
          },
          'devices',
        ),
      );
      navigate('/devices');
    } else if (!parentCompany && deviceCompany) {
      dispatch(
        setFilters(
          {
            ...filters,
            ancestor_company_id: '',
            company_id: deviceCompany._id,
          },
          'devices',
        ),
      );
      navigate('/devices');
    }
  };

  const renderHeaderRightContent = (): ReactElement => {
    return (
      <Box>
        {deviceCompany && (
          <>
            {deviceCompany.company_id !== 'machineshop' &&
              deviceCompanyParent && (
                <TypographyWithCopy
                  dataCy="device-parent-account"
                  copyTooltipText="Copy Parent Account ID"
                  typographyVariant="button"
                  tooltipPlacement="bottom-start"
                  tooltipText={`ID: ${deviceCompanyParent._id}`}
                  textClassName={classes.linkButton}
                  textToCopy={deviceCompanyParent._id}
                  text={`Parent Account: ${deviceCompanyParent.name}`}
                  handleTextClick={handleParentCompanyFilter(true)}
                />
              )}
            <TypographyWithCopy
              dataCy="device-account"
              typographyVariant="button"
              copyTooltipText="Copy Account ID"
              tooltipText={`ID: ${deviceCompany._id}`}
              textClassName={classes.linkButton}
              textToCopy={deviceCompany._id}
              text={`Account: ${deviceCompany.name}`}
              handleTextClick={handleParentCompanyFilter(false)}
            />
          </>
        )}
      </Box>
    );
  };

  const isAbleToBeSaved = (): boolean => {
    // This is to avoid the save button to get enabled when a certificate is revoked or activated.
    const newDeviceNoCertificates = cloneDeep(newDevice);
    const deviceDataNoCertificates = cloneDeep(deviceData);
    if (newDeviceNoCertificates && deviceDataNoCertificates) {
      newDeviceNoCertificates.device_certificates = [];
      deviceDataNoCertificates.device_certificates = [];
    }
    return (
      isEqual(newDeviceNoCertificates, deviceDataNoCertificates) &&
      initialHeartBeatValues?.heartbeat_period_changed === heartbeatPeriod &&
      initialHeartBeatValues?.heartbeat_time === selectedHearbeatUnit
    );
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        title="Devices content"
        goBack={goBackUrl ? goBackUrl : 'devices'}
        goBackLabel={goBackLabel || `Devices`}
        model="device"
      />
      {deviceData && (
        <Box className="content-page-container">
          <ContentHeader
            title={deviceData.name}
            contentType="device"
            profileName={deviceTypeData?.name as string}
            profileType={deviceTypeData?.type as string}
            profileRole={deviceTypeData?.role as string}
            profileId={deviceTypeData?._id as string}
            tag={deviceData.heartbeat_status}
            tagTheme={heartbeatColorThemeMap}
            subtitle={deviceData.unique_id}
            goBackUrl={`device/${deviceData._id}#${activeTab}`}
            hideOverline={false}
            rightContent={renderHeaderRightContent()}
            copySubtitleToClipboard
          />
          <VerticalTabs
            changeUrl={true}
            defaultTab={activeTab}
            tabsLabel={deviceDetailsTabsLabel}
            tabs={getTabs()}
          />
        </Box>
      )}
      <FooterBar
        deleteModalContent="You are about to delete this device"
        loading={loading}
        disableSaveButton={isAbleToBeSaved()}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDeleteDevice}
      />
      {newDevice && (
        <IssueCommandDrawer
          open={openGatewayCommand}
          devices={[newDevice]}
          singleDeviceType={deviceTypeData}
          isDevicePage={true}
          handleCloseDrawer={handleCloseGatewayCommand}
          handleSubmitSuccess={handleGatewayCommandsCallback}
          onlyShowGatewayCommands={true}
        />
      )}
      <MqttPasswordConfirmationDialog
        mqtt_password={modifiedMqttPassword ?? ''}
        onConfirm={handleConfirm}
        open={ActionDialogOpen}
        changed={true}
      />
    </Grid>
  );
};

export default DeviceContent;
