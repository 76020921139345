import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Device, DeviceCertificate, Devices } from '@edgeiq/edgeiq-api-js';
import cloneDeep from 'lodash.clonedeep';

import { useAppDispatch } from '../../../../redux/hooks';
import { setNewDevice } from '../../../../redux/reducers/devices.reducer';
import timeHelpers from '../../../../helpers/timeHelpers';
import TypographyWithCopy from '../../../../components/TypographyWithCopy';
import SwitchButton from '../../../../components/SwitchButton';
import useStyles from './styles';

interface DeviceCertificatesProps {
  device: Device;
}

// In this component we are not using `AttachTable` because it is a mess to not show the pagination and to manage the height
// of the table to be as the amount of rows. And basically this table is not an attach table. So it is easier to manage it as a simple table
// with some styling to make it look similar
const DeviceCertificates: React.FC<DeviceCertificatesProps> = ({ device }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const headerRef = useRef<HTMLTableCellElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [deviceCertificates, setDeviceCertificates] = useState<
    DeviceCertificate[]
  >([]);

  useEffect(() => {
    if (device.device_certificates) {
      setDeviceCertificates(device.device_certificates);
    }
  }, [device]);

  const handleRevokeCert =
    (certificate: DeviceCertificate, index: number) =>
    (_prop: string, value: boolean): void => {
      // A false value means revoke the certificate
      Devices.revokeCertificate(certificate.device_id, certificate.id, !value)
        .then((_result) => {
          const udpatedDevice = cloneDeep(device);
          if (udpatedDevice.device_certificates) {
            udpatedDevice.device_certificates[index].revoked = !value;
          }
          dispatch(
            setNewDevice({
              ...udpatedDevice,
            } as Device),
          );
        })
        .catch((error) => {
          console.info(error);
        });
    };

  return (
    <Box ref={containerRef}>
      <Typography className="mt-3 mb-3" variant="h5">
        Device Certificates
      </Typography>
      <table
        className={classes.table}
        style={{
          width: `${containerRef.current?.clientWidth}`,
        }}
      >
        <thead>
          <th colSpan={4} className={classes.header} ref={headerRef}>
            <div className={classes.cellContainer}>
              <Typography variant="button">Serial Number</Typography>
              <div className={classes.divider} />
            </div>
          </th>
          <th colSpan={3} className={classes.header}>
            <div className={classes.cellContainer}>
              <Typography variant="button">Status</Typography>
              <div className={classes.divider} />
            </div>
          </th>
          <th colSpan={3} className={classes.header}>
            <div className={classes.cellContainer}>
              <Typography variant="button">Issued At</Typography>
              <div className={classes.divider} />
            </div>
          </th>
          <th colSpan={3} className={classes.header}>
            <div className={classes.cellContainer}>
              <Typography variant="button">Exipres At</Typography>
            </div>
          </th>
        </thead>
        <tbody>
          {deviceCertificates.map((certificate, index) => (
            <tr key={`${certificate.id}-${index}`} className={classes.row}>
              <td colSpan={4} className={classes.cell}>
                <div className={classes.cellContainer}>
                  <TypographyWithCopy
                    dataCy={`device-certificate-${certificate.id}`}
                    typographyVariant="button"
                    text={certificate.serial_number}
                    textMaxWidth={headerRef.current?.clientWidth ?? 300}
                    tooltipText={certificate.serial_number}
                  />
                  <div className={classes.divider} />
                </div>
              </td>
              <td colSpan={3} className={classes.cell}>
                <div className={classes.cellContainer}>
                  <SwitchButton
                    topMargin={false}
                    prop="revoked"
                    label={certificate.revoked ? 'Revoked' : 'Active'}
                    value={!certificate.revoked}
                    onSwitchChange={handleRevokeCert(certificate, index)}
                  />
                  <div className={classes.divider} />
                </div>
              </td>
              <td colSpan={3} className={classes.cell}>
                <div className={classes.cellContainer}>
                  <Typography noWrap variant="button">
                    {timeHelpers.getDate(
                      certificate.issue_date,
                      false,
                      false,
                      true,
                    )}
                  </Typography>
                  <div className={classes.divider} />
                </div>
              </td>
              <td colSpan={3} className={classes.cell}>
                <div className={classes.cellContainer}>
                  <Typography noWrap variant="button">
                    {timeHelpers.getDate(
                      certificate.expiration_date,
                      false,
                      false,
                      true,
                    )}
                  </Typography>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Box>
  );
};

export default DeviceCertificates;
