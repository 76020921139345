import React, { useEffect, useState } from 'react';
import { Companies, Company, User, UserType } from '@edgeiq/edgeiq-api-js';
import { Typography, Grid, Paper, Button } from '@mui/material';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setStateNewUser } from '../../../redux/reducers/users.reducer';
import {
  AttachAccountsDrawer,
  ResetApiTokenDrawer,
} from '../../../containers/RightDrawer';
import { dispatchError, getFetchError } from '../../../helpers/utils';
import { defaultItemsPerPage, MAX_ACCOUNTS } from '../../../app/constants';
import UserForm from './userForm';
import UserAccountsList from './UserAccountsList';
import useStyles from './styles';

interface UserDetailsProps {
  isValidEmail?: boolean;
  user?: User;
  userTypes: UserType[];
}

const UserDetails: React.FC<UserDetailsProps> = ({
  user,
  userTypes,
  isValidEmail,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const errorDispatcher = dispatchError(dispatch);
  const { user: loggedUser } = useAppSelector((state: RootState) => state.user);
  const [open, setOpen] = useState(false);
  const [openAPITokenDrawer, setOpenAPITokenDrawer] = useState(false);

  const [accounts, setAccounts] = useState<Company[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  /**
   * This function is useful only until we clean up the company_ids mess we had before having the descendants table and flow.
   * For now we have some JCI users with thousands of ids in company_ids, so if we try list companies with "in" filter, we will crash the UI
   * so for now we'll use this paginating until we clean all the descendants IDs in the DB.
   */
  const paginateUserCompanyIds = (
    fetchPage: number,
    ids: string[],
  ): string[] => {
    const patch = MAX_ACCOUNTS * fetchPage;
    const startIndex = patch - MAX_ACCOUNTS;
    const lastIndex = ids.length < patch ? ids.length : patch;
    return ids.slice(startIndex, lastIndex);
  };

  const getUserAccounts = (fetchPage: number, addPage = false): void => {
    if (user?.company_ids && user?.company_ids.length !== 0) {
      setTotal(user.company_ids.length);
      setLoading(true);
      Companies.list(
        {
          _id: {
            operator: 'in',
            value: paginateUserCompanyIds(fetchPage, user.company_ids),
          },
        },
        {
          itemsPerPage: defaultItemsPerPage,
          order_by: '-created_at',
          page: fetchPage,
        },
      )
        .then((response) => {
          const userAccounts = response.companies.filter((account) => {
            return user.company_ids?.includes(account._id);
          });
          const newAccounts = addPage
            ? [...accounts, ...userAccounts]
            : userAccounts;
          setAccounts(newAccounts);
          setPage(page + 1);
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getFetchError('user attached accounts.'),
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoading(true);
    getUserAccounts(page + 1, true);
  };

  useEffect(() => {
    getUserAccounts(1);
  }, [user]);

  const handleInputChange = (prop: string, value: string | number): void => {
    dispatch(
      setStateNewUser({
        ...user,
        [prop]: value,
      } as User),
    );
  };

  const handleAccountsAdded = (accountsIds: string[]): void => {
    dispatch(
      setStateNewUser({
        ...user,
        company_ids: [...(user?.company_ids ?? []), ...accountsIds],
      } as User),
    );
    handleCloseDrawer();
  };

  const handleAccountDetached = (accountId: string): void => {
    dispatch(
      setStateNewUser({
        ...user,
        company_ids: [
          ...(user?.company_ids?.filter((id) => id !== accountId) ?? []),
        ],
      } as User),
    );
  };

  const handleOpenDrawer = (): void => {
    setOpen(true);
  };

  const handleCloseDrawer = (): void => {
    setOpen(false);
  };

  const handleOpenResetAPITokenDrawer = (): void => {
    setOpenAPITokenDrawer(true);
  };

  const handleCloseResetAPITokenDrawer = (): void => {
    setOpenAPITokenDrawer(false);
  };

  return (
    <Grid container spacing={3}>
      {user && (
        <>
          <Grid item xs={12} md={8}>
            <Paper className="p-8 shadow">
              <Typography variant="h5">Details</Typography>
              <UserForm
                user={user}
                userTypes={userTypes}
                onInputChange={handleInputChange}
                isValidEmail={isValidEmail ? isValidEmail : false}
              />
              {loggedUser?._id === user._id && (
                <Button
                  data-cy="reset-api-token-button"
                  variant="contained"
                  size="large"
                  className="mt-8"
                  onClick={handleOpenResetAPITokenDrawer}
                >
                  Reset API token
                </Button>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper className={clsx(classes.container, 'shadow p-7')}>
              <Typography
                variant="h5"
                className="mb-2"
                data-cy="user-additional-accounts-title"
              >
                Additional Accounts
                {loggedUser?._id !== user._id && (
                  <Button
                    color="primary"
                    variant="text"
                    type="button"
                    data-cy="attach-additional-account-button"
                    onClick={handleOpenDrawer}
                  >
                    <Typography variant="button">Attach</Typography>
                  </Button>
                )}
              </Typography>
              <UserAccountsList
                accounts={accounts}
                loadingAccounts={loading}
                total={total}
                user={user}
                onLoadMore={handleLoadMore}
                onAccountRemoved={handleAccountDetached}
              />
            </Paper>
          </Grid>
          <AttachAccountsDrawer
            open={open}
            user={user}
            handleCloseDrawer={handleCloseDrawer}
            onAccountsAdded={handleAccountsAdded}
          />
          <ResetApiTokenDrawer
            open={openAPITokenDrawer}
            handleCloseDrawer={handleCloseResetAPITokenDrawer}
          />
        </>
      )}
    </Grid>
  );
};

export default UserDetails;
