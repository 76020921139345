import React, { useEffect, useState } from 'react';
import { Companies, Company } from '@edgeiq/edgeiq-api-js';

import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { MAX_ACCOUNTS } from '../../app/constants';
import AccountAutocomplete from '../../components/AccountAutocomplete';

interface AccountAutocompleteProps {
  allowAdd?: boolean;
  isDisabled?: boolean;
  includeParentId?: boolean;
  parentAccountId?: string;
  placeHolder?: string;
  selectedAccount?: string;
  dataCy?: string;
  includeInfo?: boolean;
  onAccountChange?: (companyId: string) => void;
  onAccountChangeModel?: (account: Company | undefined) => void;
}

const AccountAutocompleteContainer: React.FC<AccountAutocompleteProps> = ({
  allowAdd = false,
  isDisabled,
  includeParentId = false,
  parentAccountId,
  placeHolder,
  selectedAccount,
  dataCy,
  includeInfo = true,
  onAccountChange,
  onAccountChangeModel,
}) => {
  const { userCompanies, userCompany } = useAppSelector(
    (state: RootState) => state.user,
  );
  const [accountsChildren, setAccountsChildren] = useState<Company[]>([]);
  const [searchAccounts, setSearchAccounts] = useState<Company[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);

  const getAccountModel = (accountId: string): Company | undefined => {
    let value = accountsChildren.find((account) => accountId === account._id);
    if (!value && searchAccounts.length) {
      value = searchAccounts.find((account) => accountId === account._id);
    }
    if (!value && userCompanies.length) {
      value = userCompanies.find((account) => accountId === account._id);
    }
    return value;
  };

  const handleChangeAccount = (value: string): void => {
    // No component should pass both onAccountChange and onAccountChangeModel
    if (onAccountChange) {
      onAccountChange(value as string);
    }
    if (onAccountChangeModel) {
      const account = getAccountModel(value);
      onAccountChangeModel(account);
    }
  };

  const addUserCompanyAsFirstOption = (accounts: Company[]): Company[] => {
    if (!userCompany) {
      return accounts;
    }
    return [
      userCompany,
      ...accounts.filter((acc) => acc._id !== userCompany._id),
    ];
  };

  useEffect(() => {
    if (selectedAccount) {
      handleChangeAccount(selectedAccount);
    }
    if (parentAccountId) {
      const childCompanies = userCompanies.filter(
        (company) => company.company_id === parentAccountId,
      );
      setAccountsChildren(addUserCompanyAsFirstOption(childCompanies));
    } else {
      setAccountsChildren(addUserCompanyAsFirstOption(userCompanies));
    }
  }, [userCompanies]);

  useEffect(() => {
    if (searchValue.length > 2) {
      setLoading(true);
      // First look for the result by name
      Companies.list(
        {
          search: {
            operator: 'ilike',
            value: searchValue,
          },
        },
        { itemsPerPage: MAX_ACCOUNTS, page: 1 },
      )
        .then((result) => {
          setSearchAccounts(addUserCompanyAsFirstOption(result.companies));
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (!searchValue) {
      setSearchAccounts([]);
    }
  }, [searchValue]);

  return userCompanies.length ? (
    <AccountAutocomplete
      allowAdd={allowAdd}
      isDisabled={isDisabled}
      loading={loading}
      options={searchAccounts.length ? searchAccounts : accountsChildren}
      selectedAccount={selectedAccount}
      setSearchValue={setSearchValue}
      getAccountModel={getAccountModel}
      handleChangeAccount={handleChangeAccount}
      includeParentId={includeParentId}
      includeInfo={includeInfo}
      placeHolder={placeHolder}
      dataCy={dataCy ?? 'account-autocomplete'}
    />
  ) : (
    <></>
  );
};

export default AccountAutocompleteContainer;
