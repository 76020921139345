import { Typography, TypographyVariant } from '@mui/material';
import React, { useState } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-json5';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';

import SwitchButton from '../SwitchButton';

export interface CodeEditorProps {
  prop: string;
  value?: string;
  onInputChange?: (prop: string, value: string) => void;
  label?: string;
  labelVariant?: TypographyVariant;
  width?: number;
  height?: number;
  mode?: string; // check https://securingsincity.github.io/react-ace/ to see available languages modes
  disabled?: boolean;
}

const CodeEditor: React.FC<CodeEditorProps> = ({
  prop,
  value,
  onInputChange,
  label,
  labelVariant,
  width,
  height,
  mode,
  disabled,
}) => {
  const [theme, setTheme] = useState('monokai');

  const handleChange = (newValue: string): void => {
    if (onInputChange) {
      onInputChange(prop, newValue);
    }
  };

  const handleTheme = (_prop: string, themeValue: boolean): void => {
    setTheme(themeValue ? 'monokai' : 'github');
  };

  return (
    <>
      {label && (
        <Typography
          data-testid="label"
          className="mb-2"
          variant={labelVariant ?? 'subtitle2'}
        >
          {label}
        </Typography>
      )}
      <div>
        <div className="mb-2 d-flex flex-justify-end">
          <SwitchButton
            label="Dark theme"
            value={theme !== 'github'}
            prop="theme"
            topMargin={false}
            onSwitchChange={handleTheme}
          />
        </div>
        <AceEditor
          data-cy={prop}
          enableBasicAutocompletion={true}
          enableLiveAutocompletion={true}
          enableSnippets={true}
          height={`${height}px`}
          mode={mode ?? 'javascript'}
          onChange={handleChange}
          readOnly={disabled ?? false}
          theme={theme}
          value={value}
          width={`${width}px`}
        />
      </div>
    </>
  );
};

export default CodeEditor;
