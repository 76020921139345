import { DeviceProfileAbilityGroup } from '../../models/common';

export const deviceTypeAbilitiesLabel: {
  [key: string]: string;
} = {
  deploy_deployment: 'Deploy',
  request_deployment_status: 'Request Deployment Status',
  status: 'Prompt Status',
};

export const deviceTypeAbilitiesGroups: DeviceProfileAbilityGroup[] = [
  {
    abilities: [
      {
        description:
          'Device can send alerts using Integrations set up on the account',
        key: 'notification',
        label: 'Alert',
      },
      {
        description:
          'Email Policy actions are supported for the device. Email alerts can be enabled',
        key: 'email',
        label: 'Email Alert',
      },
      {
        description:
          'SMS Policy actions are supported for the device. SMS Alerts can be enabled',
        key: 'sms',
        label: 'SMS Alert',
      },
    ],
    key: 'alerts',
    label: 'Alerts',
  },
  {
    abilities: [
      {
        description:
          'If device has a supporting integration, device can be activated through cloud integration',
        key: 'activate_cloud_native_device',
        label: 'Activate Cloud Native Device',
      },
      {
        description:
          'If device has a supporting integration, device can be deactivated through cloud integration',
        key: 'deactivate_cloud_native_device',
        label: 'Deactivate Cloud Native Device',
      },
    ],
    key: 'cloud_native_devices',
    label: 'Cloud Native Devices',
  },
  {
    abilities: [
      {
        description: 'Device can accept Heartbeat command',
        key: 'heartbeat',
        label: 'Prompt Heartbeat',
      },
      {
        description:
          'Device has ability to relay reports to EdgeIQ service for debugging and insight (For testing only)',
        key: 'relay',
        label: 'Relay',
      },
      {
        description: 'Device can be rebooted',
        key: 'reboot',
        label: 'Reboot',
      },
      {
        description: 'Device terminal can be accessed remotely',
        key: 'remote_terminal',
        label: 'Remote Terminal',
      },
      {
        description: `Device can accept an Update Config command. This triggers the device to download it's configuration from the EdgeIQ servers`,
        key: 'send_config',
        label: 'Send Config',
      },
      {
        description: 'Device can update the local Edge service',
        key: 'update_edge',
        label: 'Update Edge',
      },
    ],
    key: 'coda_devices',
    label: 'Coda Devices',
  },
  {
    abilities: [
      {
        description:
          'Settings can be sent to the device using configuration management',
        key: 'setting',
        label: 'Setting',
      },
    ],
    key: 'configuration_management',
    label: 'Configuration Management',
  },
  {
    abilities: [
      {
        description:
          'AWS IoT Core Integration with Greengrass v1/v2 is supported for the device',
        key: 'aws_iot',
        label: 'AWS IoT & Greengrass Support',
      },
      {
        description: 'Azure IoT Integration is supported for the device',
        key: 'azure_iot',
        label: 'Azure IoT',
      },
    ],
    key: 'integration_and_orchestration',
    label: 'Integration and Orchestration	',
  },
  {
    abilities: [
      {
        description:
          'Ability to log the configuration, for further review in logs',
        key: 'log_config',
        label: 'Log Config',
      },
      {
        description: 'Logging level can be set for devices',
        key: 'log_level',
        label: 'Log Level',
      },
      {
        description: 'Logs can be retrieved from the device.',
        key: 'log_upload',
        label: 'Upload Logs',
      },
    ],
    key: 'logging',
    label: 'Logging (Coda Devices)',
  },
  {
    abilities: [
      {
        description: 'Device supports LwM2M requests',
        key: 'lwm2m_request',
        label: 'LWM2M Request',
      },
    ],
    key: 'lwm2m_devices',
    label: 'LwM2M Devices	',
  },
  {
    abilities: [
      {
        description: 'Device has ability to make HTTP requests',
        key: 'http_request',
        label: 'HTTP Requests',
      },
      {
        description: 'Device has ability to connect via MQTT',
        key: 'mqtt',
        label: 'MQTT Connection',
      },
      {
        description: 'OPC-UA ingestor is supported for the device',
        key: 'opcua',
        label: 'OPC-UA Support',
      },
      {
        description:
          'TCP/UDP client and server Ingestors are supported for the device',
        key: 'tcp',
        label: 'TCP Support',
      },
      {
        description: 'TCP-MODBUS ingestor supported for the device',
        key: 'tcp_modbus',
        label: 'TCP Modbus Support',
      },
    ],
    key: 'protocol_support',
    label: 'Protocol Support',
  },
  {
    abilities: [
      {
        description: 'Device can backup firmware',
        key: 'backup',
        label: 'Backup',
      },
      {
        description:
          'Device can upload and download files to and from a device',
        key: 'file_transfer',
        label: 'File transfer',
      },
      {
        description: 'Device can accept Factory Reset command',
        key: 'reset',
        label: 'Reset',
      },
      {
        description: 'Device can restore a firmware backup',
        key: 'restore_backup',
        label: 'Restore Backup',
      },
      {
        description: 'Device can accept Software commands',
        key: 'software_update',
        label: 'Software Update',
      },
      {
        description: 'Device can perform a firmware update',
        key: 'update_firmware',
        label: 'Update Firmware',
      },
    ],
    key: 'software_updates',
    label: 'Software/Firmware Updates',
  },
];
