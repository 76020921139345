import { Setting } from '@edgeiq/edgeiq-api-js';
import { SettingsMap } from '../models/common';

export const getSettingsVersionsMapped = (
  rawSettings: Setting[],
): {
  settings: Setting[];
  subSettings: SettingsMap;
} => {
  const idsVersionsMap: { [key: string]: number } = {};
  for (let i = 0; i < rawSettings.length; i++) {
    if (
      !idsVersionsMap[rawSettings[i]._id] ||
      (idsVersionsMap[rawSettings[i]._id] &&
        idsVersionsMap[rawSettings[i]._id] < rawSettings[i].version)
    ) {
      idsVersionsMap[rawSettings[i]._id] = rawSettings[i].version;
    }
  }
  const primarySettings: Setting[] = [];
  const subSettings: SettingsMap = {};
  for (let i = 0; i < rawSettings.length; i++) {
    const item = rawSettings[i];
    if (idsVersionsMap[item._id] && idsVersionsMap[item._id] === item.version) {
      primarySettings.push(item);
    } else if (!subSettings[item._id]) {
      subSettings[item._id] = [item];
    } else {
      subSettings[item._id].push(item);
    }
  }
  return {
    settings: primarySettings,
    subSettings,
  };
};
