import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Typography, Grid, Paper, Box, CircularProgress } from '@mui/material';
import {
  Settings,
  SettingInput,
  Configuration,
  Configurations,
  Device,
  DeviceType,
  Devices,
  DeviceTypes,
} from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';
import clsx from 'clsx';

import { RootState } from '../../redux/store';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  newSettingInitialState,
  setNewSettingInput,
} from '../../redux/reducers/settings.reducer';
import { getConfigurationSelector } from '../../redux/reducers/configuration.reducer';
import {
  dispatchError,
  getAttachError,
  getCreatingError,
  getFetchError,
} from '../../helpers/utils';
import {
  initialSettingsEditorData,
  SettingsEditorData,
} from '../../models/settings';
import Header from '../../containers/HeaderWithActionButton';
import SettingForm from '../../containers/Forms/SettingsForm';
import Publish from '../../containers/Publish';
import EntitiesSection from '../../containers/EntitiesSection';
import useStyles from './styles';
import { LocationState } from '../../models/common';

const CreateSettings: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const errorDispatcher = dispatchError(dispatch);
  const location = useLocation();
  const goBackLabel = (location.state as LocationState)?.goBackLabel;
  const goBackUrl = (location.state as LocationState)?.goBackUrl;
  const copyDevices = (location.state as LocationState)?.copyDevices;
  const copyDeviceTypes = (location.state as LocationState)?.copyDeviceTypes;
  const classes = useStyles();
  const { id } = useParams<string>();
  const stateConfiguration = useAppSelector((state: RootState) =>
    getConfigurationSelector(state.configurations, id),
  );
  const [configurationData, setConfigurationData] = useState<
    Configuration | undefined | null
  >(stateConfiguration);
  const stateSetting = useAppSelector(
    (state: RootState) => state.setting.newSettingInput,
  );
  const settingInput: SettingInput = {
    ...stateSetting,
    configuration_id: id || '',
  };
  const settingsHasValue = !isEqual(settingInput?.values, {});
  const [editorData, setEditorData] = useState<SettingsEditorData>({
    ...initialSettingsEditorData,
    jsonContent: settingsHasValue
      ? JSON.stringify(settingInput.values, null, 2)
      : '',
    manualCreation: settingsHasValue,
  });
  const [selectedDevices, setSelectedDevices] = useState<Device[]>(
    copyDevices ?? [],
  );
  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<DeviceType[]>(
    copyDeviceTypes ?? [],
  );
  const [configurationDeviceTypes, setConfigurationDeviceTypes] = useState<
    DeviceType[]
  >([]);
  const [submitting, setSubmitting] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attachingDevices, setAttachingDevices] = useState(false);

  const checkSubmitEnable = (): void => {
    setEnableSubmit(settingInput?.name !== '' && settingsHasValue);
  };

  useEffect(() => {
    if (!configurationData && id) {
      setLoading(true);
      Configurations.getOneById(id)
        .then((response) => {
          handleInputChange('company_id', response.company_id);
          setConfigurationData(response);
          return Configurations.getDeviceTypes(id);
        })
        .then((response) => {
          setConfigurationDeviceTypes(response);
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getFetchError('configuration data'),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (configurationData) {
      handleInputChange('company_id', configurationData.company_id);
    }
  }, []);

  useEffect(() => {
    checkSubmitEnable();
  }, [settingInput]);

  useEffect(() => {
    if (!isEqual(editorData.jsonTree, settingInput.values)) {
      dispatch(
        setNewSettingInput({
          ...settingInput,
          values: editorData.jsonTree,
        } as SettingInput),
      );
    }
  }, [editorData.jsonTree]);

  const saveDevicesAndDeviceTypes = (settingsId: string): void => {
    if (selectedDevices.length !== 0 || selectedDeviceTypes.length !== 0) {
      setAttachingDevices(true);
      Promise.all([
        Promise.all(
          selectedDevices.map(async (device) => {
            await Devices.attachSettings(device._id, settingsId);
          }),
        ),
        Promise.all(
          selectedDeviceTypes.map(async (deviceType) => {
            await DeviceTypes.attachSettings(deviceType._id, settingsId);
            // If the configuration doesn't have the device type attached it will be
            if (
              !configurationDeviceTypes.find(
                (dt) => dt._id === deviceType._id,
              ) &&
              configurationData?._id
            ) {
              await DeviceTypes.attachConfiguration(
                deviceType._id,
                configurationData._id,
              );
            }
          }),
        ),
      ])
        .then(() => {
          console.info('Devices and Device profiles attached');
          dispatch(
            setAlert({
              highlight: 'Settings created successfully',
              type: 'success',
            }),
          );
          navigate(`/configuration/${configurationData?._id}`);
        })
        .catch((error) => {
          errorDispatcher(
            error.messages || error.message,
            getAttachError('devices and device profiles', 'configuration'),
          );
        })
        .finally(() => {
          setAttachingDevices(false);
        });
    } else {
      dispatch(
        setAlert({
          highlight: 'Settings created successfully',
          type: 'success',
        }),
      );
      navigate(`/configuration/${configurationData?._id}`);
    }
  };

  const handlePublishSubmit = (): void => {
    setSubmitting(true);
    Settings.create(settingInput as SettingInput)
      .then((result) => {
        saveDevicesAndDeviceTypes(result._id);
        dispatch(setNewSettingInput(newSettingInitialState));
      })
      .catch((error) => {
        errorDispatcher(
          error.messages || error.message,
          getCreatingError('settings'),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleInputChange = (prop: string, value: string | number): void => {
    dispatch(
      setNewSettingInput({
        ...settingInput,
        [prop]: value,
      } as SettingInput),
    );
  };

  const handleSettingsEditorChange = (
    prop: string,
    value: string | boolean | Record<string, unknown>,
  ): void => {
    setEditorData({
      ...editorData,
      [prop]: value,
    });
  };

  const handleChangeDevices = (devices: Device[]): void => {
    setSelectedDevices(devices);
  };

  const handleChangeDeviceTypes = (newDeviceTypes: DeviceType[]): void => {
    setSelectedDeviceTypes(newDeviceTypes);
  };

  return (
    <Grid container direction="row" spacing={3} className="p-9 pt-6">
      <Grid item xs={12}>
        <Header
          goBack={
            goBackUrl
              ? goBackUrl
              : `configuration/${configurationData?._id}#settings`
          }
          goBackLabel={
            goBackLabel || `Configuration: ${configurationData?.name}`
          }
          isCreatePage={true}
          model="setting"
        />
      </Grid>
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={8}>
              <Paper className="shadow p-8">
                <Box className={clsx('mb-6', classes.titleContainer)}>
                  <Typography
                    data-cy="create-settings-title"
                    variant="h5"
                    className={classes.title}
                  >
                    Create new Settings
                  </Typography>
                </Box>
                <SettingForm
                  action="create"
                  settings={settingInput as SettingInput}
                  settingsEditorData={editorData}
                  onInputChange={handleInputChange}
                  onSettingsEditorChange={handleSettingsEditorChange}
                />
                <Grid container className="mt-6">
                  <Grid item xs={12}>
                    <Typography variant="h6" className="mb-6">
                      Devices and Device Profiles
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <EntitiesSection
                      newInput={settingInput}
                      selectedDevices={selectedDevices}
                      selectedDeviceTypes={selectedDeviceTypes}
                      onChangeDevices={handleChangeDevices}
                      onChangeDeviceTypes={handleChangeDeviceTypes}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Publish
                label="account"
                isAccountDisabled={true}
                submitting={submitting}
                companyId={configurationData?.company_id}
                enableSubmit={enableSubmit && !submitting && !attachingDevices}
                onSubmit={handlePublishSubmit}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default CreateSettings;
